import DocViewer, { AvailableLanguages, DocViewerRef, DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { CSSProperties, FC, memo, useRef } from 'react';
import FileViewer from 'react-file-viewer';
import i18n from '../../i18n';
import Loader from '../../components/shared/Loader';

type DocumentFileViewerProps = {
  uri: string;
  type: string;
  style?: CSSProperties;
};

const DocumentFileViewer: FC<DocumentFileViewerProps> = (props) => {
  const { uri, type, style } = props;
  const renderDocx = type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  const docViewerRef = useRef<DocViewerRef>(null);
  const loadingRenderer = () => {
    return <Loader size={16} />;
  };
  return (
    <div className="w-full p-2" style={style}>
      {renderDocx && <FileViewer fileType={'docx'} filePath={uri} />}
      {!renderDocx && (
        <DocViewer
          ref={docViewerRef}
          documents={[{ uri: uri, fileName: '-', fileType: type }]}
          activeDocument={{ uri: uri }}
          pluginRenderers={DocViewerRenderers}
          language={i18n.language as AvailableLanguages}
          config={{
            header: {
              disableHeader: true,
            },
            pdfVerticalScrollByDefault: true,
            loadingRenderer: {
              overrideComponent: loadingRenderer,
              showLoadingTimeout: false,
            },
          }}
          theme={{
            disableThemeScrollbar: true,
            tertiary: 'transparent',
          }}
        />
      )}
    </div>
  );
};

export default memo(DocumentFileViewer);
